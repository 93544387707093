<template>
<!--  <div v-if="currentItem">
    <Transition v-if="currentItem.state && currentItem.show" appear :duration="{ enter: 500, leave: 800 }" name="fade" mode="in-out" >
      <div>{{ currentItem.desc }} {{ currentItem.loaded ? ` (${currentItem.loaded})` : '' }}...</div>
    </Transition>
  </div>-->

<!--  <div v-if="currentItem.state && currentItem.show" class="mt-05">
    {{ currentItem.desc }}
    <template v-if="currentItem.loaded">
      (<Transition appear name="fade" mode="in-out" >
        <span>{{ currentItem.loaded }}</span>
      </Transition>)...
    </template>
  </div>-->

  <div v-if="currentItem.state && currentItem.show" class="mt-05">
    {{ currentItem.desc }}...
    <template v-if="currentItem.loaded">
      (<span>{{ currentItem.loaded }}</span>)
    </template>
  </div>
</template>

<script>
export default {
  name: 'OverlayLoadingText',
  props: {
    currentItem: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      item: this.currentItem
    }
  },
  /*watch: {
    currentItem: {
      deep: true,
      handler(item) {
        this.item = item
      }
    },
  }*/
}
</script>

<style>
.mt-05 {
  margin-top: .5rem;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
