export default {
    methods: {
        print(elementId) {
            const allCssStylesheetsLinks = [];

            // get reference to all stylesheets
            const stylesheets = document.styleSheets;

            // looping through each stylesheet
            // and checking if there is href property in each item
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < stylesheets.length; i++) {
                if(stylesheets[i].href) {
                    allCssStylesheetsLinks.push(stylesheets[i].href)
                }
            }

            const options = {
                name: '_blank',
                specs: [
                    'fullscreen=yes',
                    'titlebar=yes',
                    'scrollbars=yes'
                ],
                styles: [
/*                    ...new Set(
                        [...window.document.styleSheets]
                            .map(style => (style.href !== null
                                    ? style.href
                                    : `data:text/css;base64,${btoa(
                                        unescape(
                                            encodeURIComponent(
                                                [...style.cssRules].map(rule => rule.cssText).join('')
                                            )
                                        )
                                    )}`))
                            .filter(s => s !== null && s !== 'data:text/css;base64,')
                    ),*/
                    '//unpkg.com/bootstrap-vue@latest/dist/bootstrap-vue.min.css',
                    '//unpkg.com/bootstrap@4.6.1/dist/css/bootstrap.min.css',
                ],
                timeout: 1000, // default timeout before the print window appears
                autoClose: true, // if false, the window will not close after printing
                windowTitle: window.document.title, // override the window title
            }

            options.styles.push(...allCssStylesheetsLinks);
            this.$htmlToPaper(elementId, options)
        },
    },
}
