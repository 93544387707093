<template>
  <keep-alive>
    <div>
      <!-- Page Breadcrumbs -->
      <template >
        <portal to="page-breadcrumbs" class="portal-to-breadcrumbs">
          <b-breadcrumb id="page-layout-breadcrumbs">
            <b-breadcrumb-item id="page-layout-breadcrumb-home" to="/">
<!--              <feather-icon icon="HomeIcon" size="16" class="align-text-top" />-->
              <b-icon-house-door style="width: 16px; height: 16px;"/>
            </b-breadcrumb-item>
            <!-- Page Breadcrumbs Slot -->
            <slot name="breadcrumbs" :state="state" :year="$store.getters['settings/getCurrentYear']">
              <!-- Default Breadcrumbs -->
              <b-breadcrumb-item v-for="item in $store.state.breadcrumb.breadcrumbs" :key="item.text" :active="item.active" :to="item.to" :text="item.text" />
            </slot>
          </b-breadcrumb>
        </portal>
      </template>

      <!-- Page Actions & Options -->
      <template v-if="!state.loading">
        <portal to="page-options" class="portal-to-page-options">
          <div class="d-flex align-items-center">
            <slot name="options" :state="state">
              <!-- Page Options Slot -->
            </slot>

            <div id="page-layout-actions" class="d-inline-flex">
              <slot name="actions" :state="state">
                <!-- Page Actions Slot -->
              </slot>
            </div>

            <b-dropdown id="page-layout-options" v-b-tooltip="'Options'" variant="link" no-caret toggle-class="p-0 ml-1" right :class="{ show: tourProps.options.show }">
              <template #button-content>
                <b-button variant="link" size="sm" class="btn-icon border-0">
<!--                  <font-awesome-icon icon="fa-solid fa-gear"></font-awesome-icon>-->
                  <b-icon-gear-fill style="width: 16px; height: 16px;" />
                </b-button>
              </template>
              <template>
                <slot name="dropdown-options" :state="state">
                  <!-- Page Options Slot -->
                </slot>

                <!-- Global Options -->

                <b-dropdown-item v-if="showPrintOption" id="page-layout-option-print" :disabled="state.saving" @click="windowPrint()"> <!--print('page-layout-content')-->
                  <feather-icon icon="PrinterIcon"/>
                  <span class="align-middle ml-50">Print</span>
                </b-dropdown-item>

                <template v-if="$scopedSlots.tour">
                  <b-dropdown-divider />
                  <b-dropdown-item id="page-layout-option-help" @click="$tours[$route.name].start()">
                    <font-awesome-icon icon="fa-solid fa-circle-question"></font-awesome-icon>
                    <span class="align-middle ml-50">Help</span>
                  </b-dropdown-item>
                </template>

                <template v-if="$can('change.year')">
                  <b-dropdown-divider />
                  <b-dropdown-group header="Year">
                    <b-dropdown-item v-for="option in $store.getters['settings/getEnabledOptions']" :key="option.id"
                                     :active="$store.state.settings.app.current.id === option.id" :disabled="state.saving" @click="updateStoreSettingApp(option)">
                      <div class="d-flex align-items-center">
                        <b-icon :icon="$store.state.settings.app.current.id === option.id ? 'calendar2-check-fill' : 'calendar2'"/>
                        <span class="align-middle ml-1 justify-content-center">{{ option.title }}</span>
                      </div>
                    </b-dropdown-item>
                  </b-dropdown-group>

                </template>

                <template v-if="$slots.debug && $can('manage', 'debug')">
                  <b-dropdown-divider />
                  <b-dropdown-group header="Developer">
                    <b-dropdown-item @click="toggleDebugMode()">
                      <feather-icon icon="CodeIcon"/>
                      <span class="align-middle ml-50">Debug: {{ state.debug ? 'On' : 'Off' }} </span>
                    </b-dropdown-item>
                  </b-dropdown-group>
                </template>
              </template>
            </b-dropdown>
          </div>

        </portal>
      </template>

      <!-- Page Content -->
      <template>
        <slot name="modal" :state="state">
          <!-- Page Modal Slot -->
        </slot>

        <div id="page-layout-content">
          <b-overlay :show="state.loading" variant="white" opacity="1" :rounded="true">
            <!-- Content: Loading -->
            <template #overlay>
              <slot name="loading" :state="state">

              </slot>
            </template>
            <template v-if="state.loading">
              <b-row>
                <b-col>
                  <b-card>
                    <!-- This entire template is just to give body to the overlay while loading -->
                  </b-card>
                </b-col>
              </b-row>
            </template>
            <!-- Content -->
            <template v-else>
              <slot name="tour" :tourName="this.$route.name"></slot>
              <slot name="content" :state="state">
                <!-- Default Content Slot -->
              </slot>
            </template>
            <!-- Content: Debug -->
            <template v-if="$slots.debug && state.debug && $can('debug')" >
              <div class="mt-2 d-print-none">
                <hr>
                <slot name="debug">
                  <!-- Should only include <debug>{{ var }}</debug> -->
                </slot>
              </div>
            </template>
          </b-overlay>
        </div>
      </template>
    </div>
  </keep-alive>
</template>

<script>
  import print from '@/mixins/print.mixin'
  import OverlayLoading from '@/components/OverlayLoading.vue';
  import {API, graphqlOperation} from 'aws-amplify';
  import {listUserSchools, onUpdateUser} from '@/graphql/queries/users';
  import {adminGetUser, adminListGroupsForUser} from '@/scripts/aws';
  import {onUpdateSetting} from '@/graphql/subscriptions';

  export default {
    name: 'PageLayout',
    components: {
      OverlayLoading,
    },
    mixins: [ print ],
    props: {
      tourProps: {
        type: Object,
        default: () => ({
          options: {
            show: false
          },
        })
      },
      showPrintOption: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        state: {
          loading: true,
          creating: false,
          editing: false,
          saving: false,
          debug: false,
          error: false,
        },
        subscriptions: {
          onUpdate: null,
        }
      }
    },
    watch: {
      '$store.state.settings.app': {
        immediate: true,
        handler(newApp, oldApp) {
          if (newApp && newApp.current && oldApp && oldApp.current) {
            if (newApp.current?.id !== oldApp.current?.id) {
              this.$emit('refresh');
            }
          }
        },
      },
    },
    created() {
      this.$store.dispatch('settings/subscribeToSettings')
    },

    methods: {
      toggleDebugMode() {
        this.state.debug = !this.state.debug
      },
      confirmDelete(payload, callback, options) {
        const SWAL_CONFIRM = () => ({
          icon: options?.confirm?.icon || 'warning',
          title: options?.confirm?.title || 'Are you sure?',
          text: options?.confirm?.text || "You won't be able to revert this!",
          html: options?.confirm?.shouldParse ? options?.confirm?.text : null,
          allowOutsideClick: false,
          showCancelButton: true,
          confirmButtonText: options?.confirm?.button?.text || 'Yes, delete it!',
          customClass: {
            confirmButton: options?.confirm?.button?.class || 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
        const SWAL_SUCCESS = () => ({
          icon: options?.confirmed?.icon || 'success',
          title: options?.confirmed?.title || 'Deleted!',
          text: options?.confirmed?.text || 'Data has been deleted.',
          customClass: {
            confirmButton: 'btn btn-success',
          },
          timer: options?.confirmed?.timer?.time || 60000,
          timerProgressBar: options?.confirmed?.timer?.progress || true,
        })
        const SWAL_ERROR = (error) => ({
          icon: options?.error?.icon || 'error',
          title: options?.error?.title || 'Error!',
          text: options?.error?.text || error?.message || 'Something went wrong.',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
          timer: options?.error?.timer?.time || 60000,
          timerProgressBar: options?.error?.timer?.progress || true,
        })

        this.$swal(SWAL_CONFIRM()).then(result => {
          if (result.value) {
            //Delete the item from the database with the callback function
            this.$swal({
              title: 'Processing...',
              text: 'Please wait',
              html: `
                        <div class="swal2-progress-bar-wrapper">
                            <div class="swal2-progress-bar"></div>
                        </div>
                    `,
              footer: '<div></div>',
              allowOutsideClick: false,
              showCancelButton: false,
              showConfirmButton: false,
              onBeforeOpen: () => {
                // Define the progress callback function
                const updateProgress = (progress) => {
                  // Update the Swal progress bar width
                  const progressBar = document.querySelector('.swal2-progress-bar');
                  if (progressBar) {
                    progressBar.style.width = `${progress.percent}%`;
                  }

                  // Update the Swal title or text to show the progress
                  const footer = this.$swal.getFooter();
                  if (footer) {
                    footer.innerHTML = `${progress.title} - ${progress.index} of ${progress.total} (${progress.percent}%)`;
                  }
                };

                // Run the callback function here:
                callback(payload, updateProgress).then(() => {
                  this.$swal.close();
                  this.$swal(SWAL_SUCCESS())
                })
                .catch((error) => {
                  this.$swal.close();
                  this.$swal(SWAL_ERROR(error))
                })
              },
            });
          }
        })
      },
      async updateStoreSettingApp(option) {
        if(this.$store.state.settings.app.current.id !== option.id) {
          await this.$store.dispatch('settings/loadSettingsApp')
          this.$store.commit('settings/setSettingsAppCurrent', option)
          this.$emit('refresh')
        }
      },
      windowPrint() {
        window.print()
      }
    }
  }
</script>

<style>
  #page-layout-breadcrumbs {
    padding: 0.3rem 0.25rem;
  }
  .swal2-popup {
    min-width: 25vw;
    width: auto;
    max-width: 33vw;
  }
  .swal2-header {
    padding: 1rem 1rem 0.25rem 1rem;
  }
  .swal2-title {
    font-size: 1.5em;
  }
  .swal2-content {
    padding: 0.25rem 1rem 1rem 1rem;
    font-size: 1rem;
  }
  .swal2-icon.swal2-warning {
    border-color: #ea5455;
    color: #ea5455;
  }
  .swal2-progress-bar-wrapper {
    width: 100%;
    height: 20px;
    background-color: #eee;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 20px;
  }

  .swal2-progress-bar {
    height: 100%;
    width: 0%;
    background-color: #3085d6 !important;
    transition: width 0.3s;
  }
</style>

<style v-if="$scopedSlots.tour" lang="scss">
  @import '~@core/scss/vue/libs/tour.scss';
</style>

<style lang="scss">

#page-layout-options .dropdown-menu {
  min-width: 15rem;
}

@media print {

  // Global Styles
  body {
    background-color: transparent !important;
    //min-width: unset !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }
  a:link { text-decoration: none; color: #6e6b7b; }
  a:visited { text-decoration: none; color: #6e6b7b; }
  a:hover { text-decoration: none; color: #6e6b7b; }
  a:active { text-decoration: none; color: #6e6b7b; }

  .app-content.content {
    padding: 0 !important;
  }

  /*.content-header {
    display: none!important;
  }*/

  .btn-scroll-to-top {
    display: none !important;
  }
}

</style>
