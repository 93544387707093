<template>
  <div class="text-center match-height" :class="wrapperClass">
    <b-spinner v-if="show" label="Loading...." /> <br/>
    <small>
      <overlay-loading-text v-for="(item) in list" :key="item.desc + '_' + item.loaded" :current-item.sync="item" />
    </small>
  </div>
</template>

<script>
import OverlayLoadingText from '@/components/OverlayLoadingText.vue';

export default {
  name: 'OverlayLoading',
  components: { OverlayLoadingText },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    show: {
      type: Boolean,
      default: true
    },
    wrapperClass: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      item: true,
      list: this.items.map(item => ({...item, show: false}) )
    }
  },
  computed: {
    currentItem() {
      const item = this.list?.filter(i => i.state)[0]
      item.show = true
      return item
    },
    filteredList() {
      return this.list
          .filter(i => i.state)
          .every((i, index) => {
            if (index === 0) {
              i.show = true
              return false;
            }
            return true
          })
    }
  },
  watch: {
    items: {
      deep: true,
      handler(items) {
        items.forEach((item, index) => {
          if(!item.state) {
            item.show = true
          }
          else if(index === 0) {
            item.show = true
          }
        })
        this.list = items
      }
    }
  }
}
</script>

<style scoped>

</style>
