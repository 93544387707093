// eslint-disable-next-line import/prefer-default-export
export const listUsers = /* GraphQL */ `
    query ListUsers(
        $id: ID
        $filter: ModelUserFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
        $includeSchools: Boolean = false
    ) {
        listUsers(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                username
                name {
                    first
                    last
                }
                schools @include(if: $includeSchools) {
                    items {
                        id
                        school {
                            id
                            name {
                                legal
                                popular
                            }
                        }
                    }
                }
                avatar {
                    size
                    name
                    src
                }
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;
export const listUserSchools = /* GraphQL */ `
    query ListUserSchools(
        $filter: ModelUserSchoolsFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listUserSchools(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                userID
                school {
                    id
                    name {
                        legal
                        popular
                    }
                }
                createdAt
                updatedAt
            }
        }
    }
`;


export const listSchools = /* GraphQL */ `
    query ListSchools(
        $id: ID
        $filter: ModelSchoolFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listSchools(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name {
                    legal
                }
            }
            nextToken
        }
    }
`;

export const updateUser = /* GraphQL */ `
    mutation UpdateUser(
        $input: UpdateUserInput!
        $condition: ModelUserConditionInput
    ) {
        updateUser(input: $input, condition: $condition) {
            id
            username
            name {
                first
                last
            }
            address {
                line1
                line2
                city
                county
                state
                zip
            }
            schools {
                items {
                    id
                    schoolID
                    userID
                    createdAt
                    updatedAt
                }
                nextToken
            }
            ensembleIds
            instrumentIds
            avatar {
                size
                name
                src
            }
            createdAt
            updatedAt
            districtUsersId
        }
    }
`;

export const deleteUser = /* GraphQL */ `
    mutation DeleteUser(
        $input: DeleteUserInput!
        $condition: ModelUserConditionInput
    ) {
        deleteUser(input: $input, condition: $condition) {
            id
            username
            name {
                first
                last
            }
            address {
                line1
                line2
                city
                county
                state
                zip
            }
            schools {
                items {
                    id
                    schoolID
                    userID
                    createdAt
                    updatedAt
                }
                nextToken
            }
            ensembleIds
            instrumentIds
            avatar {
                size
                name
                src
            }
            createdAt
            updatedAt
            districtUsersId
        }
    }
`;
export const deleteUserSchools = /* GraphQL */ `
    mutation DeleteUserSchools(
        $input: DeleteUserSchoolsInput!
        $condition: ModelUserSchoolsConditionInput
    ) {
        deleteUserSchools(input: $input, condition: $condition) {
            id
            schoolID
            userID
            school {
                id
                beds
                slug
                name {
                    legal
                    popular
                }
                avatar {
                    size
                    name
                    src
                }
                address {
                    line1
                    line2
                    city
                    county
                    state
                    zip
                }
                zone {
                    id
                    name
                    counties
                    createdAt
                    updatedAt
                }
                district {
                    id
                    beds
                    slug
                    createdAt
                    updatedAt
                    districtZoneId
                }
                staff {
                    type
                    title
                }
                users {
                    nextToken
                }
                state {
                    enabled
                }
                createdAt
                updatedAt
                districtSchoolsId
                schoolZoneId
            }
            user {
                id
                username
                name {
                    first
                    last
                }
                address {
                    line1
                    line2
                    city
                    county
                    state
                    zip
                }
                schools {
                    nextToken
                }
                ensembleIds
                instrumentIds
                avatar {
                    size
                    name
                    src
                }
                createdAt
                updatedAt
                districtUsersId
            }
            createdAt
            updatedAt
        }
    }
`;

export const onCreateUser = /* GraphQL */ `
    subscription OnCreateUser {
        onCreateUser {
            id
            username
            name {
                first
                last
            }
            address {
                line1
                line2
                city
                county
                state
                zip
            }
            schools {
                items {
                    id
                    schoolID
                    userID
                    createdAt
                    updatedAt
                }
                nextToken
            }
            ensembleIds
            instrumentIds
            avatar {
                size
                name
                src
            }
            createdAt
            updatedAt
            districtUsersId
        }
    }
`;
export const onUpdateUser = /* GraphQL */ `
    subscription OnUpdateUser {
        onUpdateUser {
            id
            username
            name {
                first
                last
            }
            address {
                line1
                line2
                city
                county
                state
                zip
            }
            schools {
                items {
                    id
                    schoolID
                    userID
                    createdAt
                    updatedAt
                }
                nextToken
            }
            ensembleIds
            instrumentIds
            avatar {
                size
                name
                src
            }
            createdAt
            updatedAt
            districtUsersId
        }
    }
`;
export const onDeleteUser = /* GraphQL */ `
    subscription OnDeleteUser {
        onDeleteUser {
            id
        }
    }
`;

